import React from "react"
import { graphql } from "gatsby"
import { ContentWrapper } from "../../components"

import Layout from "../../layouts/en"

const GeneratedPageEN = props => {
  const {
    html,
    excerpt,
    frontmatter: { title },
  } = props.data.markdownRemark

  return (
    <Layout location={props.location} title={title} description={excerpt}>
      <ContentWrapper title={title} markdownHTML={html} />
    </Layout>
  )
}

export default GeneratedPageEN

export const query = graphql`
  query GeneratedPageEN($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(format: PLAIN)
      frontmatter {
        title
      }
    }
  }
`
